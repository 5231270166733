<template>
  <div class="container">
    <h1 class="main-claim">Aufbau von lebendigem Boden</h1>
    <p class="sub-claim">
      Unser Hauptziel ist es, Boden aufzubauen. <strong>Boden, der Pflanzen ernährt</strong>, damit Pflanzen Tiere und
      Menschen
      ernähren können. <strong>Ohne lebendigen Boden gibt es überhaupt kein Leben.</strong>
    </p>
    <div class="claim-pictures">
      <figure>
        <img src="@/assets/images/landing/2022-12-02_13-19-27.jpg" />
        <figcaption>Wir versuchen aus diesem</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/2022-04-19_12-21.jpg" />
        <figcaption>das und noch mehr zu machen</figcaption>
      </figure>
    </div>

    <h1 class="main-claim">Spezialitäten</h1>
    <p class="sub-claim">
      Wenn sich unser Boden verbessert und immer fruchtbarer wird und dadurch mehr Pflanzen und Tiere ernährt,
      <strong>entsteht Überfluss und wir können den Überschuss ernten</strong>. Zwei Beispiele unserer
      Lebensmittelspezialitäten:
    </p>
    <div class="claim-pictures">
      <figure>
        <img src="@/assets/images/landing/2022-11-11_11-45-16.jpg" />
        <figcaption>Schinken vom iberischen Schwein aus Eichelmast</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/2022-12-03_13-35-09.jpg" />
        <figcaption>Scharfer Chili, angebaut in der heißen Sonne Andalusiens</figcaption>
      </figure>
    </div>

    <h1 class="main-claim">Tiere in einer Umgebung, die die Natur nachahmt</h1>
    <p class="sub-claim">
      In jedem <strong>Ökosystem</strong> spielen Tiere eine wichtige Rolle. Wir studieren ihr natürliches Verhalten und
      zielen darauf
      ab, eine <strong>Nachahmung der Natur zu erschaffen</strong>, die es ihnen ermöglicht, ihre <strong>Instinkte voll
        auszuleben</strong>.
    </p>
    <div class="claim-pictures">
      <figure>
        <img src="@/assets/images/landing/2022-11-24_11-56-04.jpg" />
        <figcaption>Iberische Schweine</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/2022-04-02_11-58-52.jpg" />
        <figcaption>Nicht domestizierte Tiere</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/cattle.jpg" />
        <figcaption>Robuste und gemischtrassige Rinder</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/2022-04-25_20-06-54.jpg" />
        <figcaption>Katzen und andere Tiere, die bei uns leben</figcaption>
      </figure>
    </div>

    <h1 class="main-claim">Wir erschaffen Überfluss und entnehmen den Überschuss</h1>
    <p class="sub-claim">
      Die Natur lebt vom Überfluss und daher sind <strong>natürliche Systeme widerstandsfähiger</strong>. Wir agieren im
      Mittelmeerklima mit im <strong>Sommer anhaltender Trockenheit ohne Regen für etwa 6 Monate</strong>. Wir entnehmen
      aus dem System nur den Überschuss, so dass z.B. unser Boden immer <strong>vor der sehr kräftigen Sonne geschützt
        ist</strong>.
    </p>
    <div class="claim-pictures">
      <figure>
        <img src="@/assets/images/landing/2022-04-08_18-54-20.jpg" />
        <figcaption>Überfluss an Gras</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/2022-04-25_20-15-53.jpg" />
        <figcaption>Ständiger Weidewechsel</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/2021-03-31_13-34-30.jpg" />
        <figcaption>Lkw-Ladungen an Biomasse</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/horns03.jpg" />
        <figcaption>Gesunde Tiere</figcaption>
      </figure>
    </div>

    <h1 class="main-claim">Der Wert bestimmt den Preis</h1>
    <p class="sub-claim">
      Transparenz und klare Kommunikation sind uns sehr wichtig. Sie als unser Kunde können so den Wert jedes
      Produktes erkennen und <strong>beim Kauf den eigenen Preis bestimmen</strong>.
    </p>
  </div>
</template>

<script>
export default {
  name: "HomeIndexDE",
  components: {},
};
</script>
<style scoped>

</style>
